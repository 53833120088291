<template>
  <div id="dynamic" class="scroll--indicator">Scroll</div>
  <section class="hero">
    <div class="container hero-body">
      <h1 class=“title”>Web Development: My Passion</h1>
      <h2 class=“subtitle”>Delivering Seamless User Experiences</h2>
    </div>
  </section>

  <section class="my-services">
    <div class="container mb-3">
      <h2 class="title">What I can offer</h2>
      <p class="col-12 col-md-8 pb-3 mx-auto subtitle">As a web developer with a background in design, I offer a range
        of services to help you create a strong online
        presence and engage your audience. Whether you need a new website, a custom form, or ongoing maintenance, I can
        help you achieve your goals.</p>
      <div class="d-flex flex-wrap justify-content-center align-items-top">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="icon-box"><img :src="require('@/assets/media/browser-website.svg')"></div>
          <h3 class="subtitle">UI/UX Design</h3>
          <p>Creating intuitive and engaging user interfaces that prioritize user experience. My designs are informed by
            user feedback and usability testing to ensure they meet both user needs and business goals.</p>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="icon-box"><img :src="require('@/assets/media/apps.svg')"></div>
          <h3 class="subtitle">Web & App Design</h3>
          <p>Designing visually appealing and user-friendly web and mobile applications. I focus on creating cohesive
            and interactive experiences that keep users engaged.</p>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="icon-box"><img :src="require('@/assets/media/html-website.svg')"></div>
          <h3 class="subtitle">Website Development</h3>
          <p>Building responsive and dynamic websites that adapt seamlessly across all devices. From concept to
            deployment, I ensure your site is fast, secure, and scalable.</p>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="icon-box"><img :src="require('@/assets/media/web-support.svg')"></div>
          <h3 class="subtitle">Website Maintenance</h3>
          <p>Providing ongoing support and updates to keep your website running smoothly. I handle everything from
            security updates to content management, ensuring your site remains up-to-date and functional.</p>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="icon-box"><img :src="require('@/assets/media/forms.svg')"></div>
          <h3 class="subtitle">Bespoke Forms</h3>
          <p>Developing custom forms tailored to your specific needs. Whether it’s for lead generation, surveys, or
            feedback, I create forms that are easy to use and integrate seamlessly with your website.</p>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="icon-box"><img :src="require('@/assets/media/email-design.svg')"></div>
          <h3 class="subtitle">Email HTML Templates</h3>
          <p>Designing and coding responsive email templates that look great on any device. I ensure your emails are
            visually appealing and deliver your message effectively.</p>
        </div>
      </div>
    </div>
  </section>

  <section class="cta">
    <div class="container">
      <h2 class="col-12 col-md-8  mx-auto title">Looking for a skilled Web Developer?</h2>
      <div class="col-12 col-md-8 p-3 mx-auto">
        <p>
          I’m currently seeking new opportunities to leverage my expertise in building responsive, dynamic websites and
          applications. With a focus on creating seamless user experiences and robust, scalable solutions, I’m ready to
          bring my skills to your team. Let’s connect!
        </p>
      </div>
      <div class="d-flex justify-content-center gap-3">
        <a href="https://www.linkedin.com/in/pjchia/" target="_blank" class="btn btn-outline-dark">
          <font-awesome-icon :icon="['fab', 'linkedin']" size="1x" class="linkedin-icon" /> Connect on LinkedIn
        </a>
        <a href="mailto:chiawebs.com@gmail.com" target="_blank" class="btn btn-outline-dark">
          <font-awesome-icon :icon="['fas', 'square-envelope']" size="1x" class="email-icon" /> Email Me
        </a>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'home-page',
  data() {
    return {
      chiawebsTaglineLogo: require('@/assets/media/chiawebs-tagline-logo.png')
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollIndicator = document.querySelector('.scroll--indicator');
      if (window.scrollY > 100) { // Adjust the scroll value as needed
        scrollIndicator.classList.add('hidden');
      } else {
        scrollIndicator.classList.remove('hidden');
      }
    }
  }
}
</script>

<style scoped>
.hero {
  background-image: url('../assets/media/hero-banner.png');
  background-size: cover;
  background-position: center center;
  color: white;
  text-align: center;
  height: calc(100vh - 160px);
  margin: 0;

  .hero-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: transparent;
    background: radial-gradient(circle, rgba(238, 238, 238, 1) 5%, rgba(238, 238, 238, 0.80) 20%, rgba(255, 255, 255, 0) 70%);

    h1 {
      text-align: center !important;

      @media (max-width: 768px) {
        font-size: 2.25em;
        margin-bottom: 1em;
      }
    }

  }
}

h2 {
  text-align: center;

  &.title {
    font-size: 2em;
    margin: 1em 0;
    color: #00ADB5;
    font-weight: 400;
  }
}

.icon-box {
  width: 250px;
  height: 250px;
  margin: 0.75em auto;

  @media (max-width: 768px) {
    width: 60%;
    height: auto;
    margin: 0.5em auto;
  }
}

.my-services {
  margin: 5em auto;

  p {
    font-size: 0.9em;
    padding: 0 1.5em;

    &.subtitle {
      font-size: 1.25em;
      margin: 1em 0;
    }
  }
}

.cta {
  background-color: #00ADB5;
  color: white;
  text-align: center;
  padding: 5em 0;

  h2 {
    font-size: 2em;
    margin: 0;
    color: #fff;
  }

  p {
    font-size: 1.25em;

  }

  .btn {
    margin-top: 1em;
  }
}

.scroll--indicator {
  display: none;
  position: fixed;
  z-index: 11;
  left: 20px;
  bottom: 110px;
  font-size: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #393E46;
  transform: rotate(-90deg);
  transition: all 0.3s linear 0s;
}

.scroll--indicator::after {
  position: absolute;
  top: 7px;
  margin: 0px 0px 0px 3px;
  content: "";
  width: 50px;
  height: 1px;
  background: #393E46;
}

@media (min-width: 1400px) {
  .scroll--indicator {
    display: block;
  }
}

.hidden {
  display: none !important;
}
</style>