<template>
  <HeaderMenu />
  <router-view />

  <div class="container d-flex justify-content-center align-items-center pt-3 mt-3"
    style="border-top: 1px dashed #EEEEEE;">
    <p class="text-center">
      &copy; {{ new Date().getFullYear() }} ChiaWebs
    </p>
  </div>
</template>

<script>
import HeaderMenu from './components/HeaderMenu.vue';

export default {
  name: 'App',
  components: {
    HeaderMenu
  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@import '@/assets/scss/main.scss';
</style>
