<template>
  <div class="container portfolio">
    <h1>Portfolio</h1>
    <p class="p-3 m-0 text-start">
      Below is a showcase of select projects I've contributed to over the years, each accompanied by a concise overview of the skills and tools I employed. These projects span various domains, reflecting my ability to adapt and excel across different technological landscapes. For each project, I detail my specific contributions and the key outcomes achieved, demonstrating my commitment to delivering value through innovative solutions.
    </p>
    <div class="row container py-3 px-0 m-auto">
      <h2 class="col-md-12 text-center">Recent Projects</h2>
      <div class="col-md-6 col-lg-4 port-item" v-for="(project, index) in projects" :key="index">
        <div class="cover">
          <a type="button" data-bs-toggle="modal" data-bs-target="#projectPrev" @click="showModal(project.src)">
            <img :src="project.src" :alt="project.alt">
          </a>
        </div>

        <div class="p-3" v-for="(content, pIndex) in project.content" :key="pIndex" v-html="content"></div>

        <!-- Modal -->
        <div class="modal fade" id="projectPrev" tabindex="-1" aria-labelledby="projectPrevLabel" aria-hidden="true"
          v-show="selectedImage">
          <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="btn-close" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <img :src="selectedImage" :alt="project.alt">
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="row container py-3 px-0 m-auto">
      <h2>Past Projects</h2>
      <div class="row d-flex justify-content-start my-3">
        <div class="col-md-6 col-lg-4 port-item">
          <div class="cover">
            <a href="#" data-bs-toggle="modal" data-bs-target="#eatgreen">
              <img src="/assets/media/eatgreen-thumb.jpg">
            </a>

            <div class="modal fade" id="eatgreen" tabindex="-1" aria-labelledby="eatgreen-Label" style="display: none;"
              aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <img src="/assets/media/eatgreen-preview.jpg">
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="container-fluid text-center p-3">
            <a href="https://eatgreen.hk/" target="_blank" type="button" rel="nofollow">EATGREEN.HK</a>
          </div>

          <div class="skill-overview">
            <ul>
              <li>UI/UX Design and Prototyping (Adobe XD)</li>
              <li>Web Design and Development</li>
              <li>Hugo, Custom Theme Design</li>
              <li>JSON, Javascript. jQuery</li>
              <li>FoxyCart</li>
              <li>Bootstrap</li>
              <li>Azure DevOps</li>
              <li>Git</li>
              <li>SEO</li>
              <li>Multi-language Implementation</li>
            </ul>
          </div>

        </div>

        <div class="col-md-6 col-lg-4 port-item">
          <div class="cover">
            <a href="#" data-bs-toggle="modal" data-bs-target="#ilikesausage">
              <img src="/assets/media/ilikesausage-thumb.jpg">
            </a>

            <div class="modal fade" id="ilikesausage" tabindex="-1" aria-labelledby="ilikesausage-Label"
              style="display: none;" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <img src="/assets/media/ilikesausage-preview.jpg">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid text-center p-3">
            <a href="https://ilikesausage.hk/" target="_blank" type="button" rel="nofollow">ILIKESAUSAGE.HK</a>
          </div>
          <div class="skill-overview">
            <ul>
              <li>UI/UX Design and Prototyping (Adobe XD)</li>
              <li>Web Design and Development</li>
              <li>Hugo, Custom Theme Design</li>
              <li>JSON, Javascript. jQuery</li>
              <li>FoxyCart</li>
              <li>Bootstrap</li>
              <li>Azure DevOps</li>
              <li>Git</li>
              <li>SEO</li>
              <li>Multi-language Implementation</li>
            </ul>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 port-item">
          <div class="cover">
            <a href="#" data-bs-toggle="modal" data-bs-target="#imanagesystems">
              <img src="/assets/media/imanagesystems-thumb.jpg">
            </a>

            <div class="modal fade" id="imanagesystems" tabindex="-1" aria-labelledby="imanagesystemsLabel"
              style="display: none;" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <img src="/assets/media/imanagesystems-preview.png">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid text-center p-3">
            <a href="https://www.imanagesystems.com/" target="_blank" type="button"
              rel="nofollow">Integrated Management Systems (IMS)</a>
          </div>
          <div class="skill-overview">
            <ul>
              <li>Web Design and Development</li>
              <li>SEO, Lucky Orange, Ahrefs</li>
              <li>FormAssembly</li>
              <li>WordPress, Salient Theme</li>
              <li>PHP</li>
              <li>MySQL</li>
              <li>Javascript, jQuery</li>
              <li>Blog Layout</li>
              <li>Multi-language Implementation</li>
            </ul>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 port-item">
          <div class="cover">
            <a href="#" data-bs-toggle="modal" data-bs-target="#jk7skincare">
              <img src="/assets/media/jk7skincare-thumb.jpg">
            </a>

            <div class="modal fade" id="jk7skincare" tabindex="-1" aria-labelledby="jk7skincareLabel"
              style="display: none;" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <img src="/assets/media/jk7skincare-preview.png">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-3" style="font-weight: 600;">JK7 Skincare</div>
          <div class="skill-overview">
            <ul>
              <li>Web Design and Development (Adobe XD)</li>
              <li>SEO</li>
              <li>WordPress, Porto Theme</li>
              <li>Migration: Magento to WooCommerce</li>
              <li>Content migration</li>
              <li>PHP, MySQL</li>
              <li>US and EU tax implementation</li>
              <li>Javascript, jQuery</li>
              <li>Payment gateway implementation</li>
            </ul>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 port-item">
          <div class="cover">
            <a href="#" data-bs-toggle="modal" data-bs-target="#meatmarket-draft">
              <img src="/assets/media/meatmarket-draft-thumb.jpg">
            </a>

            <div class="modal fade" id="meatmarket-draft" tabindex="-1" aria-labelledby="meatmarket-draftLabel"
              style="display: none;" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <img src="/assets/media/meatmarket-draft-preview.jpg">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-3" style="font-weight: 600;">
            <a href="https://www.meatmarket.com.hk/" target="_blank" type="button"
              rel="nofollow">meatmarket.hk</a>
            </div>
          <div class="skill-overview">
            <ul>
              <li>UI/UX and Web Design - <a href="https://www.figma.com/proto/8ELweUa1KiMVokidOhhLva/meatmarket.hk---prototype?node-id=37-3743&t=o8idstPbbxV2Hrm3-1" target="_blank" rel="nofollow">Mobile UI Sample</a></li>
              <li>Prototyping (Adobe XD, Figma)</li>
              <li>Web Development (UAT)</li>
              <li>Hugo</li>
              <li>JSON, Javascript. jQuery</li>
              <li>FoxyCart</li>
              <li>Bootstrap</li>
              <li>Azure DevOps</li>
              <li>Git</li>
            </ul>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 port-item">
          <div class="cover">
            <a href="#" data-bs-toggle="modal" data-bs-target="#wholefoods-asia">
              <img src="/assets/media/wholefoods-asia-thumb.jpg">
            </a>

            <div class="modal fade" id="wholefoods-asia" tabindex="-1" aria-labelledby="wholefoods-asiaLabel"
              style="display: none;" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <img src="/assets/media/wholefoods-asia-preview.png">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h5></h5>
          <div class="container-fluid text-center p-3">
            <a href="https://wholefoodsasia.com/" target="_blank" type="button"
              rel="nofollow">Wholefoods Asia</a>
          </div>
          <div class="skill-overview">
            <ul>
              <li>Web Design and Development (Adobe XD)</li>
              <li>SEO</li>
              <li>Zoho Forms</li>
              <li>Bootstrap</li>
              <li>Javascript</li>
            </ul>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 port-item">
          <div class="cover">
            <a href="#" data-bs-toggle="modal" data-bs-target="#algemy">
              <img src="/assets/media/algemy-thumb.jpg">
            </a>

            <div class="modal fade" id="algemy" tabindex="-1" aria-labelledby="algemyLabel" style="display: none;"
              aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <img src="/assets/media/algemy-preview.png">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid text-center p-3">
            <a href="https://algemy.earth/" target="_blank" type="button" rel="nofollow">Algemy</a>
          </div>
          <div class="skill-overview">
            <ul>
              <li>Web Design and Development</li>
              <li>Bootstrap</li>
            </ul>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 port-item">
          <div class="cover">
            <a href="#" data-bs-toggle="modal" data-bs-target="#starvin-marvin">
              <img src="/assets/media/starvin-marvin-thumb.jpg">
            </a>

            <div class="modal fade" id="starvin-marvin" tabindex="-1" aria-labelledby="starvin-marvinLabel"
              style="display: none;" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <img src="/assets/media/starvin-marvin-preview.jpg">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-3" style="font-weight: 600;">Starvinmar.vin</div>
          <div class="skill-overview">
            <ul>
              <li>Web Design</li>
              <li>Web Development (UAT)</li>
              <li>Bootstrap</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-sm-10 col-lg-7 mb-2 text-center mx-auto p-3">
        <h3>Other brands and projects I had the privilege to work with:</h3>
        Sense of Touch, Chi-Med, Food Union China, AuCoeurDuLuxe, Ryder Diamonds, OrgHive, Property Raptor, Harbinger,
        Implus Asia, TriggerPoint, Earth's Best, Avalon Organics, Arnold Classic, Iles Formula, Bimaio,
        Isbilen, Shamrock Catering, and many more...
      </div>
    </div>
  </div>
</template>

<script>
/* global bootstrap */

export default {
  name: 'portfolio-page',
  data() {
    return {
      projects: [
        {
          src: require('@/assets/media/project-cic-gcsfe-2023.png'),
          alt: 'project-cic-gcsfe-2023',
          content: [
            '<a href="https://www.cic.hk/gcsfe" target="_blank" rel="nofollow">CIC GCSFE 2023</a><div class="p-2">Front-end (Bootstrap), Backend UI customization & CMS integration (Laravel)</div>'
          ]
        },
        {
          src: require('@/assets/media/project-tea-2024.png'),
          alt: 'project-tea-2024',
          content: ['<a href="https://thaiexhibition.or.th/" target="_blank" rel="nofollow">Thai Exhibition Association</a><div class="p-2">WordPress revamp & migration, custom plugin for members directory (Vue), Web Design & Layout</div>']
        },
        {
          src: require('@/assets/media/project-tmxexpo-2024.png'),
          alt: 'project-tmxexpo-2024',
          content: ['<a href="https://tmxexpo.com/" target="_blank" rel="nofollow">TMX Expo 2024</a><div class="p-2">WordPress, custom plugin for exhibitor listing (Vue), Web Design & Layout</div>']
        },
        {
          src: require('@/assets/media/project-chinaplas-2024.png'),
          alt: 'project-chinaplas-2024',
          content: ['<a href="https://ecatalogue24.chinaplasonline.com/" target="_blank" rel="nofollow">Chinaplas 2024</a><div class="p-2">Front-end (Tailwind, Alpine) and UI/UX customization (Laravel), multi-language content</div>']
        },
        {
          src: require('@/assets/media/project-parknshop-2022.png'),
          alt: 'project-parknshop-2022',
          content: ['<span style="font-weight: 600;">ParknShop 2022 Event</span><div class="p-2">Tailor-made rsvp registration form (Laravel)</div>']
        },
        {
          src: require('@/assets/media/project-prada-mode-seoul.png'),
          alt: 'project-prada-mode-seoul',
          content: ['<span style="font-weight: 600;">Prada Mode Seoul Event</span><div class="p-2">Tailor-made rsvp registration form (Laravel)</div>']
        },
        {
          src: require('@/assets/media/project-wealthexpo-2024.png'),
          alt: 'project-wealthexpo-2024',
          content: ['<span style="font-weight: 600;">Wealth Expo 2024</span><div class="p-2">Tailor-made rsvp registration form (Laravel)</div>']
        },
        {
          src: require('@/assets/media/project-beyond-food-expo-2023.png'),
          alt: 'project-beyond-food-expo-2023',
          content: ['<span style="font-weight: 600;">Beyond Food Expo 2023</span><div class="p-2">Tailor-made rsvp registration form (Laravel)</div>']
        },
        {
          src: require('@/assets/media/project-digitech-2023.png'),
          alt: 'project-digitech-2023',
          content: ['<span style="font-weight: 600;">DigiTech ASEAN Thailand 2023 Survey</span><div class="p-2">Tailor-made survey form (Laravel)</div>']
        }
      ],
      selectedImage: null,
      chiawebsSmallLogo: require('@/assets/media/chiawebs-small-logo.png'),
      chiawebsSocialLogo: require('@/assets/media/chiawebs-social-logo.png'),
    }
  },
  methods: {
    showModal(src) {
      this.selectedImage = src;
      var myModal = new bootstrap.Modal(document.getElementById('projectPrev'), {
        focus: true
      });
      myModal.show();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '@/assets/scss/main.scss';
</style>