<template>
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-4 col-lg-3 portrait">
                <div class="side-box">
                    <img src="@/assets/media/chiawebs-patrick.png">
                    <div class="pt-3">
                        <a href="https://www.linkedin.com/in/pjchia/" target="_blank">
                            <font-awesome-icon :icon="['fab', 'linkedin']" size="2x" class="linkedin-icon" />
                        </a>
                        <a href="mailto:chiawebs.com@gmail.com" target="_blank">
                            <font-awesome-icon :icon="['fas', 'square-envelope']" size="2x" class="email-icon" />
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-8 col-lg-9 about-info">
                <section>
                    <p class="sub-header pb-3">Hi, my name is <span style="color: #00ADB5;">Patrick</span> and I am a
                        <span style="color: #00ADB5;">web developer</span> based in Hong Kong.
                    </p>
                    <p>I’m primarily a Front-End Web Developer with over 15 years of experience building rich and
                        interactive websites. I leverage responsive design and SEO to create user-friendly experiences.
                        Passionate about implementing cutting-edge web technologies to deliver solutions that drive
                        customer satisfaction and business growth. I have the ability to follow industry and
                        technological trends and to adopt new practices.
                    </p>

                    <p>I have diverse industry experience, having worked in digital transformation agencies, stock
                        photography, luxury lifestyle brands, food delivery & logistics, and event & exhibition
                        management systems.</p>
                </section>

                <section>
                    <h2>Skills</h2>
                    <div class="accordion" id="skillsAccordion">
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="frontend-heading">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#frontend-collapse" aria-expanded="true"
                                    aria-controls="frontend-collapse">
                                    Front-End
                                </button>
                            </h3>
                            <div id="frontend-collapse" class="accordion-collapse collapse show"
                                aria-labelledby="frontend-heading" data-bs-parent="#skillsAccordion">
                                <div class="accordion-body">
                                    <ul>
                                        <li>HTML, CSS, Sass, JavaScript</li>
                                        <li><strong>Libraries & Frameworks:</strong> Bootstrap, Tailwind
                                            CSS, jQuery, Alpine.js, Vue.js, React (Familiar)</li>
                                        <li><strong>Tools:</strong> Webpack, npm/yarn</li>
                                        <li><strong>UI/UX Design:</strong> Responsive Design, Cross-Browser
                                            Compatibility,
                                            Performance Optimization</li>
                                        <li><strong>Template Engines:</strong> Smarty, Liquid</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="backend-heading">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#backend-collapse" aria-expanded="false"
                                    aria-controls="backend-collapse">
                                    Back-End
                                </button>
                            </h3>
                            <div id="backend-collapse" class="accordion-collapse collapse"
                                aria-labelledby="backend-heading" data-bs-parent="#skillsAccordion">
                                <div class="accordion-body">
                                    <ul>
                                        <li><strong>Languages:</strong> PHP</li>
                                        <li><strong>Frameworks:</strong> Laravel</li>
                                        <li><strong>Databases:</strong> SQL, MySQL, NoSQL (JSON)</li>
                                        <li><strong>APIs:</strong> RESTful (Experience in consuming APIs, not in
                                            creating them)</li>
                                        <li><strong>Familiar with:</strong> C++, Nginx, Apache, Docker, MongoDB, Node.js
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="platform-heading">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#platform-collapse" aria-expanded="false"
                                    aria-controls="platform-collapse">
                                    Development Ecosystem
                                </button>
                            </h3>
                            <div id="platform-collapse" class="accordion-collapse collapse"
                                aria-labelledby="platform-heading" data-bs-parent="#skillsAccordion">
                                <div class="accordion-body">
                                    <ul>
                                        <li><strong>Version Control:</strong> Git, GitHub, GitLab</li>
                                        <li><strong>Cloud Services:</strong> AWS, Azure</li>
                                        <li><strong>Content Management Systems (CMS):</strong> WordPress, Joomla, Drupal
                                        </li>
                                        <li><strong>E-commerce Platforms:</strong> WooCommerce, Shopify, BigCommerce,
                                            X-Cart, Magento, FoxyCart</li>
                                        <li><strong>Static Site Generators (SSG):</strong> Hugo</li>
                                        <li><strong>Website Builders:</strong> Webflow, Wix</li>
                                        <li><strong>Operating Systems:</strong> Linux, Windows, macOS</li>
                                        <li><strong>Project Management:</strong> Wrike, Zoho, Monday.com</li>
                                        <li><strong>Marketing Automation:</strong> MailChimp, FormAssembly</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="design-heading">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#design-collapse" aria-expanded="false"
                                    aria-controls="design-collapse">
                                    UI/UX Design
                                </button>
                            </h3>
                            <div id="design-collapse" class="accordion-collapse collapse"
                                aria-labelledby="design-heading" data-bs-parent="#skillsAccordion">
                                <div class="accordion-body">
                                    <ul>
                                        <li><strong>Design Principles:</strong> User-Centered Design, Accessibility,
                                            Usability</li>
                                        <li><strong>Wireframing & Prototyping:</strong> Sketch, Figma, Adobe XD</li>
                                        <li><strong>Graphic Design:</strong> Adobe Illustrator, Adobe Photoshop</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="seo-heading">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#seo-collapse" aria-expanded="false" aria-controls="seo-collapse">
                                    SEO (Search Engine Optimization)
                                </button>
                            </h3>
                            <div id="seo-collapse" class="accordion-collapse collapse" aria-labelledby="seo-heading"
                                data-bs-parent="#skillsAccordion">
                                <div class="accordion-body">
                                    <ul>
                                        <li><strong>On-Page SEO:</strong> Keyword Research, Meta Tags, Content
                                            Optimization</li>
                                        <li><strong>Technical SEO:</strong> Site Speed, Mobile Optimization, Schema
                                            Markup</li>
                                        <li><strong>Off-Page SEO:</strong> Link Building, Social Media Integration</li>
                                        <li><strong>Tools:</strong> Google Analytics, Google Tag Manager, Google Search
                                            Console, SEMrush, Ahrefs, Hotjar, Lucky Orange, Google AdWords</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="softskills-heading">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#softskills-collapse" aria-expanded="false"
                                    aria-controls="softskills-collapse">
                                    Soft Skills
                                </button>
                            </h3>
                            <div id="softskills-collapse" class="accordion-collapse collapse"
                                aria-labelledby="softskills-heading" data-bs-parent="#skillsAccordion">
                                <div class="accordion-body">
                                    <ul class="skills-list">
                                        <li>Strong Problem-Solving Skills</li>
                                        <li>Can-Do Attitude</li>
                                        <li>Excellent Team Player</li>
                                        <li>Time Management</li>
                                        <li>Project Management</li>
                                        <li>Strong Attention to Detail</li>
                                        <li>Continuous Learning</li>
                                        <li>Fast Learner and Adaptable</li>
                                        <li>Calm, Cheerful, and Patient</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <h2>Work Experience</h2>
                    <div class="timeline">
                        <div class="timeline-item">
                            <div class="timeline-period">
                                <p>Apr 2023 - May 2024</p>
                            </div>
                            <div class="timeline-content">
                                <h3>Software Developer</h3>
                                <p>EPISODE Limited, San Po Kong, Hong Kong</p>
                                <p>
                                    EPISODE offers comprehensive technology solutions for various events, handling
                                    everything from e-invitations and online registration to efficient check-ins and
                                    NFC/RFID payment systems. Their solutions ensure reliable and accurate
                                    management of event data and processes, regardless of the event’s scale.
                                </p>
                            </div>
                        </div>
                        <div class="timeline-item">
                            <div class="timeline-period">
                                <p>July 2020 - Jan 2023</p>
                            </div>
                            <div class="timeline-content">
                                <h3>Product Manager</h3>
                                <p>Wholefoods Asia Limited, Wong Chuk Hang, Hong Kong</p>
                                <p>Wholefoods Asia's Starvinmar.vin, a startup technology, offers a versatile shopping
                                    experience by recipe, diet, allergies, and more. They provide multilingual nutrition
                                    and religious diet labeling, along with meal plans, subscriptions, and mixed boxes.
                                    Their platform supports web, mobile, POS, wholesale, and Deliveroo sales, and even
                                    allows launching an online, multivendor food hall.</p>
                            </div>
                        </div>
                        <div class="timeline-item">
                            <div class="timeline-period">
                                <p>Jan 2016 - July 2020</p>
                            </div>
                            <div class="timeline-content">
                                <h3>Senior Web Developer</h3>
                                <p>Integrated Management Systems (IMS), Wong Chuk Hang, Hong Kong</p>
                                <p>
                                    IMS is a digital marketing agency and digital transformation firm that shapes global
                                    consumer behavior through a data-driven, agile, and ROI-focused approach. They help
                                    established organizations and new brands with growth marketing strategies, data
                                    analytics, e-commerce optimization, social media marketing, WeChat mini-programs,
                                    and digital marketing in China.
                                </p>
                            </div>
                        </div>
                        <div class="timeline-item">
                            <div class="timeline-period">
                                <p>April 2010 - Dec 2015</p>
                            </div>
                            <div class="timeline-content">
                                <h3>Web Developer</h3>
                                <p>Wholefoods Asia Limited, Ap Lei Chau, Hong Kong</p>
                                <p>Wholefoods Asia's meatmarket.hk, Hong Kong’s most successful online store of
                                    high-quality meats and produce conveniently, contactlessly delivered to thousands of
                                    HK households.</p>
                            </div>
                        </div>
                        <div class="timeline-item">
                            <div class="timeline-period">
                                <p>May 2004 - March 2010</p>
                            </div>
                            <div class="timeline-content">
                                <h3>Web Master</h3>
                                <p>Oriental Touch Limited, Quarry Bay, Hong Kong</p>
                                <p>Oriental Touch was a stock photo agency based in Hong Kong. They offer a website
                                    where users can search for royalty-free (RF) and rights-managed (RM) images.
                                    Additionally, they distribute their extensive photo library to global resellers,
                                    providing a wide range of high-quality images for various needs.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <h2>Education</h2>
                    <h3>Bachelor of Science in Computer Science</h3>
                    <p>Xavier University, Cagayan de Oro City, Philippines</p>
                    <h3>Diploma in Graphic Design with Illustrator and Photoshop</h3>
                    <p>Welkin Systems Limited, Central, Hong Kong</p>
                </section>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'AboutUs',
};
</script>

<style scoped lang="scss">
ul {
    padding-left: 1em;
    margin: 0;

    &.skills-list {
        display: grid;
        grid-template-columns: 1fr;

        @media (min-width: 1200px) {
            grid-template-columns: 1fr 1fr;
        }
    }

    li {
        list-style-type: disc;
        list-style-position: outside;
        display: list-item;

        &::marker {
            color: #00ADB5;
            margin-left: 0;
        }
    }
}


.linkedin-icon {
    color: #404141;
}

.email-icon {
    color: #404141;
    margin-left: 10px;
}

.container {
    padding: 3em 2em;

    .about-info {

        text-align: left;
        line-height: 1.5;

        .sub-header {
            font-size: 1.5em;
            font-weight: bold;
        }

        section {
            padding: 2em 0 1em;

            h2 {
                border-bottom: 3px solid #00ADB5;
                padding-bottom: 0.25em;
                width: max-content;
            }
        }

        .accordion-button {
            background-color: #00ADB5;
            color: #fff;
        }

        .accordion-button:hover,
        .accordion-button:focus {
            background-color: #00ADB5;
            color: #fff;
        }

        .accordion-item {
            background-color: #f8f9fa;
            border: 1px solid #eee;
            border-radius: 0.25rem;
        }

        .accordion-button:focus {
            box-shadow: 0 0 0 0.25rem rgba(0, 173, 181, 0.5);
        }

        .timeline {
            position: relative;
            max-width: 100%;
            margin: 0 auto;
        }

        .timeline::after {
            content: '';
            position: absolute;
            width: 4px;
            background-color: #00ADB5;
            top: 0;
            bottom: 0;
            left: 30%;
            margin-left: -2px;
        }

        .timeline-item {
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            position: relative;
            background-color: inherit;
            width: 100%;
        }

        .timeline-item::after {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            left: 30%;
            background-color: white;
            border: 4px solid #00ADB5;
            top: 12px;
            border-radius: 50%;
            z-index: 1;
            transform: translateX(-50%);
        }

        .timeline-period {
            width: 28%;
            text-align: right;
            padding-right: 20px;
            box-sizing: border-box;

            p {
                text-align: right;
            }
        }

        .timeline-content {
            width: 67%;
            padding-left: 20px;
            box-sizing: border-box;
            position: relative;
            border-radius: 6px;
        }

        .timeline-content h3 {
            margin-top: 0;
            color: #00ADB5;
        }
    }

    p {
        text-align: left;
    }

    .portrait {
        position: relative;

        .side-box {
            position: fixed;

            img {
                object-fit: contain;
                width: 100%;
                max-width: 200px;
                height: auto;
            }
        }
    }
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .container {
        .about-info {
            padding-top: 1em;
            padding-left: 0;
            line-height: 1.25;
        }

        .portrait {
            .side-box {
                position: relative;

                img {
                    object-fit: contain;
                    width: 100%;
                    max-width: 200px;
                    height: auto;
                }
            }
        }

        .timeline-item {
            .timeline-period {
                max-width: 33%;
                text-align: justify;
            }
        }
    }
}
</style>