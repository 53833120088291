
import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import 'bootstrap/dist/css/bootstrap.css';
import Bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';

window.bootstrap = Bootstrap;

// Import the components
import App from './App.vue';
import Home from './components/Home.vue';
import AboutUs from './components/AboutUs.vue';
import Portfolio from './components/Portfolio.vue';
import VueGtm from 'vue-gtm';
import FontAwesomeIcon from './fontawesome';
import { createHead } from '@vueuse/head';

// add more routes here
const routes = [
  { 
    path: '/', 
    component: Home,
    meta: { title: 'Home | ChiaWebs' }
  },
  { 
    path: '/portfolio', 
    component: Portfolio,
    meta: { title: 'Portfolio | ChiaWebs' }
  },
  { 
    path: '/about', 
    component: AboutUs,
    meta: { title: 'About Me | ChiaWebs' }
  },
  // add more routes here
];

// Create the router
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Add a global navigation guard to set the document title
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'ChiaWebs';
  
  next();
});

// Create and mount the app
const app = createApp(App);

// Initialize vue-gtm for Google Tag Manager
app.use(VueGtm, {
  id: 'GTM-M22HJFM', // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  enabled: process.env.NODE_ENV === 'production', // true or false to enable or disable (defaults to true, you can set to false based on env var)
  debug: process.env.NODE_ENV !== 'production', // Whether or not display console logs debugs (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
});

// Initialize @vueuse/head
const head = createHead();
app.use(head);

app.use(router);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');